<template>
  <div class="bg-status" :class="orderStatusBackground">
    <div class="mr-1 dot" :class="orderStatusDot" />
    <ion-text class="status-text" :class="orderStatusColor">{{ orderStatus }}</ion-text>
  </div>
  <div class="block-order-detail-button">
    <block-order-detail :orderId="orderId" v-if="isBlocking" />
  </div>
</template>
<script>
import BlockOrderDetail from '@/modules/shared/components/order/BlockOrderDetail';
import { ORDER_STATUS_NAME, USER_TYPE } from '@/modules/shared/constants';
import { helpOutline } from 'ionicons/icons';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    BlockOrderDetail
  },
  props: {
    orderStatusDetail: {
      type: Object,
      default: null
    },
    isBookingOrder: {
      type: Boolean,
      default: false
    },
    parent: {
      type: String,
      default: ''
    },
    orderId: {
      type: Number,
      default: 0
    },
    lastOrderStatus: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { t } = useI18n();
    // cek order status for set background status style
    const orderStatusBackground = computed(() => {
      let orderStatus = '';
      if (props.parent === 'orderDetail') {
        const orderLogsInfo = props.orderStatusDetail?.last_order_log_info;
        orderStatus = orderLogsInfo ? orderLogsInfo.status : props.orderStatusDetail?.order_status;
      } else {
        orderStatus = props.lastOrderStatus;
      }

      return [
        ORDER_STATUS_NAME.COMPLETED,
        ORDER_STATUS_NAME.DELIVERY_SUCCEED,
        ORDER_STATUS_NAME.DELIVERY_SUCCESSFUL
      ].includes(orderStatus)
        ? 'bg-green'
        : [
            ORDER_STATUS_NAME.CANCELLED,
            ORDER_STATUS_NAME.FAILED_DELIVERY,
            ORDER_STATUS_NAME.BLOCKED
          ].includes(orderStatus)
        ? 'bg-red'
        : orderStatus === ORDER_STATUS_NAME.PROCESSING || orderStatus === ORDER_STATUS_NAME.AMENDED
        ? 'bg-grey-status'
        : 'bg-orange';
    });

    // cek order status for set dot background status style
    const orderStatusDot = computed(() => {
      let orderStatus = '';
      if (props.parent === 'orderDetail') {
        const orderLogsInfo = props.orderStatusDetail?.last_order_log_info;
        orderStatus = orderLogsInfo ? orderLogsInfo.status : props.orderStatusDetail?.order_status;
      } else {
        orderStatus = props.lastOrderStatus;
      }
      return [
        ORDER_STATUS_NAME.COMPLETED,
        ORDER_STATUS_NAME.DELIVERY_SUCCEED,
        ORDER_STATUS_NAME.DELIVERY_SUCCESSFUL
      ].includes(orderStatus)
        ? 'green'
        : [
            ORDER_STATUS_NAME.CANCELLED,
            ORDER_STATUS_NAME.FAILED_DELIVERY,
            ORDER_STATUS_NAME.BLOCKED
          ].includes(orderStatus)
        ? 'red'
        : orderStatus === ORDER_STATUS_NAME.PROCESSING || orderStatus === ORDER_STATUS_NAME.AMENDED
        ? 'grey'
        : 'orange';
    });

    // cek order status for set status title color
    const orderStatusColor = computed(() => {
      let orderStatus = '';
      if (props.parent === 'orderDetail') {
        const orderLogsInfo = props.orderStatusDetail?.last_order_log_info;
        orderStatus = orderLogsInfo ? orderLogsInfo.status : props.orderStatusDetail?.order_status;
      } else {
        orderStatus = props.lastOrderStatus;
      }
      return [
        ORDER_STATUS_NAME.COMPLETED,
        ORDER_STATUS_NAME.DELIVERY_SUCCEED,
        ORDER_STATUS_NAME.DELIVERY_SUCCESSFUL
      ].includes(orderStatus)
        ? 'text-green'
        : [
            ORDER_STATUS_NAME.CANCELLED,
            ORDER_STATUS_NAME.FAILED_DELIVERY,
            ORDER_STATUS_NAME.BLOCKED
          ].includes(orderStatus)
        ? 'text-red'
        : orderStatus === ORDER_STATUS_NAME.PROCESSING || orderStatus === ORDER_STATUS_NAME.AMENDED
        ? 'text-grey'
        : 'text-orange';
    });

    // set order status title
    const orderStatus = computed(() => {
      let orderStatus = '';
      let isCancelledBySupplier = false;
      if (props.parent === 'orderDetail') {
        const orderLogsInfo = props.orderStatusDetail?.last_order_log_info;
        orderStatus = orderLogsInfo ? orderLogsInfo.status : props.orderStatusDetail?.order_status;
        isCancelledBySupplier = USER_TYPE.SALE.includes(orderLogsInfo.changed_by_user_type);
      } else {
        const orderLogsInfo = props.orderStatusDetail[0];
        orderStatus = props.lastOrderStatus;
        isCancelledBySupplier = USER_TYPE.SALE.includes(orderLogsInfo.changed_by_user_type);
      }

      return orderStatus === ORDER_STATUS_NAME.CANCELLED && !isCancelledBySupplier
        ? t('order_cancelled', {
            isOrderBooking: props.isBookingOrder ? t('booking') : t('orderB2b.order')
          })
        : orderStatus === ORDER_STATUS_NAME.PROCESSED
        ? t('order_processed', {
            isOrderBooking: props.isBookingOrder ? t('booking') : t('orderB2b.order')
          })
        : orderStatus === ORDER_STATUS_NAME.PROCESSING || orderStatus === ORDER_STATUS_NAME.AMENDED
        ? t('order_processing', {
            isOrderBooking: props.isBookingOrder ? t('booking') : t('orderB2b.order')
          })
        : orderStatus === ORDER_STATUS_NAME.COMPLETED
        ? !props.isBookingOrder
          ? t('order_completed')
          : t('booking_confirmed')
        : orderStatus === ORDER_STATUS_NAME.CANCELLED && isCancelledBySupplier
        ? t('order_cancel_by_supplier', {
            isOrderBooking: props.isBookingOrder ? t('booking') : t('orderB2b.order')
          })
        : orderStatus === ORDER_STATUS_NAME.DELIVERY_STARTED
        ? t('delivery_started')
        : orderStatus === ORDER_STATUS_NAME.DELIVERY_SUCCEED ||
          orderStatus === ORDER_STATUS_NAME.DELIVERY_SUCCESSFUL
        ? t('delivered')
        : orderStatus === ORDER_STATUS_NAME.FAILED_DELIVERY
        ? t('failed_delivery')
        : orderStatus === ORDER_STATUS_NAME.BLOCKED
        ? t('on_hold')
        : '-';
    });

    const isBlocking =
      props.parent === 'orderDetail'
        ? props.orderStatusDetail?.last_order_log_info?.status === ORDER_STATUS_NAME.BLOCKED
        : props.lastOrderStatus === ORDER_STATUS_NAME.BLOCKED;

    return {
      orderStatusBackground,
      orderStatusDot,
      orderStatusColor,
      orderStatus,
      helpOutline,
      isBlocking
    };
  }
});
</script>

<style src="../style.scss" lang="scss" scoped></style>
